import { types } from './type';
import API from '../api';
import { message } from 'antd';

const { serviceList, addService,addNewService, changeStatus,updateService,deleteService } = API;

export const getServiceList = (data) => (dispatch) => {
  dispatch({ type: types.SHOW_LOADING, loading: true });
  serviceList()
    .then((res) => {
      if (res.data.success) {
        dispatch({ type: types.GET_SERVICE_LIST, data: res.data.data });
        dispatch({ type: types.SHOW_LOADING, loading: false });
        if (data) {
          message.success({ content: `List Updated`, duration: 2 });
        }
      } else {
        dispatch({ type: types.SHOW_LOADING, loading: false });
        message.success({ content: `${res.data.message}`, duration: 2 });
      }
    })
    .catch(() => {
      // setLoading(false)
    });
};

export const addServices = (data,newService,callback) => (dispatch) => {
  dispatch({ type: types.SHOW_LOADING, loading: true });
  addService(data,newService)
    .then((res) => {
      if (res.data.success) {
        callback(true,res.data.data)
        dispatch(getServiceList(true));
        dispatch({ type: types.SHOW_LOADING, loading: false });
        message.success({ content: `${res.data.message}`, duration: 2 });
      } else {
        callback(false)
        dispatch({ type: types.SHOW_LOADING, loading: false });
        message.warning({ content: `${res.data.message}`, duration: 2 });
      }
    })
    .catch((e) => {
      // setLoading(false)
      dispatch({ type: types.SHOW_LOADING, loading: false });
      message.warning({ content: `${e?.response?.data.message}`, duration: 2 });
      // message.success({content: `${e.message}`, duration: 2});
    })
};
export const deleteServices = (data) => (dispatch) => {
  dispatch({ type: types.SHOW_LOADING, loading: true });
  deleteService(data)
    .then((res) => {
      if (res.data.success) {
        dispatch(getServiceList(true));
        dispatch({ type: types.SHOW_LOADING, loading: false });
        message.success({ content: `${res.data.message}`, duration: 2 });
      } else {
        dispatch({ type: types.SHOW_LOADING, loading: false });
        message.warning({ content: `${res.data.message}`, duration: 2 });
      }
    })
    .catch((e) => {
      // setLoading(false)
      dispatch({ type: types.SHOW_LOADING, loading: false });
      // message.success({content: `${e.message}`, duration: 2});
      message.warning({ content: `${e?.response?.data.message}`, duration: 2 });
    })
    .catch((e) => {
      // setLoading(false)
      dispatch({ type: types.SHOW_LOADING, loading: false });
      // message.success({content: `${e.message}`, duration: 2});
    });
};
export const updateServices = (data,callback) => (dispatch) => {
  dispatch({ type: types.SHOW_LOADING, loading: true });
  updateService(data)
    .then((res) => {
      if (res.data.success) {
        callback(true)
        dispatch(getServiceList(true));
        dispatch({ type: types.SHOW_LOADING, loading: false });
        message.success({ content: `${res.data.message}`, duration: 2 });
      } else {
        callback(false)
        dispatch({ type: types.SHOW_LOADING, loading: false });
        message.warning({ content: `${res.data.message}`, duration: 2 });
      }
    })
    .catch((e) => {
      callback(false)
      // setLoading(false)
      dispatch({ type: types.SHOW_LOADING, loading: false });
      message.warning({ content: `${e?.response?.data.message}`, duration: 2 });
      // message.success({content: `${e.message}`, duration: 2});
    })
    .catch((e) => {
      // setLoading(false)
      dispatch({ type: types.SHOW_LOADING, loading: false });
      // message.success({content: `${e.message}`, duration: 2});
    });
};
export const updateStatus = (data) => (dispatch) => {
  dispatch({ type: types.SHOW_LOADING, loading: false });
  changeStatus(data)
    .then((res) => {
      if (res.data.success) {
        dispatch(getServiceList(true));
        dispatch({ type: types.CHANGE_SERVICE_STATUS, payload: data });
        dispatch({ type: types.SHOW_LOADING, loading: false });
        // message.success({content: `${res.data.message}`, duration: 2});
      } else {
        dispatch({ type: types.SHOW_LOADING, loading: false });
        message.success({ content: `${res.data.message}`, duration: 2 });
      }
    })
    .catch((e) => {
      // setLoading(false)
      dispatch({ type: types.SHOW_LOADING, loading: false });
      // message.success({content: `${e.message}`, duration: 2});
    })
    .catch((e) => {
      // setLoading(false)
      dispatch({ type: types.SHOW_LOADING, loading: false });
      // message.success({content: `${e.message}`, duration: 2});
    });
};
