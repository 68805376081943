import React from 'react';
import {Drawer,Switch,Tag,Button } from 'antd';
import { 
	CalendarOutlined, 
	EditOutlined, 
	UserOutlined, 
	CloseOutlined, CheckOutlined, UnorderedListOutlined, DollarOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { connect } from "react-redux"

import {updateStatus} from '../../../actions/serviceAction';

export const ServiceView = (props) => {
    const { data } = props;
    const changeStatus = (status) => {
        let param = {
            serviceID: data.id,
            status: status?1:0
        }
        props.updateStatus(param);
    }

    return (
        <Drawer
            width={300}
            placement="right"
            onClose={props.close}
            closable={false}
            visible={props.visible}
        >
            {/* <div className="text-center mt-3">
            <Avatar size={80} src={data?.logo?data?.logo:<UserOutlined style={{fontSize:30}}/>} />
                <h3 className="mt-2 mb-0">{data?.first_name+' '+data?.last_name }</h3>
                <span className="text-muted">{data?.company_name}</span>
            </div>
            <Divider dashed /> */}
            <div className="">
                <h6 className="text-muted text-uppercase mb-3">Service details</h6>
                <p>
                    <UnorderedListOutlined />
                    <span className="ml-3 text-dark">Service id: {data?.id}</span>
                </p>
                <p>
                    <UnorderedListOutlined />
                    <span className="ml-3 text-dark">Service Group: {data?.service_group_name.param_description}</span>
                </p>
                <p>
                    <UnorderedListOutlined />
                    <span className="ml-3 text-dark">Service: {data?.service_name.param_description}</span>
                </p>
                <p>
                    <DollarOutlined />
                    <span className="ml-3 text-dark">Price: {data?.price}</span>
                </p>
                <p>
                    <DollarOutlined />
                    <span className="ml-3 text-dark">Tax: {data?.tax+' %'}</span>
                </p>
                <p>
                    <CalendarOutlined />
                    <span className="ml-3 text-dark">Valid From: {moment(data?.price_valid_from).format('MM/DD/YYYY')}</span>
                </p>
                <p>
                    <CalendarOutlined />
                    <span className="ml-3 text-dark">Valid To: {moment(data?.price_valid_till).format('MM/DD/YYYY')}</span>
                </p>
                <p>
                    <EditOutlined />
                    <span className="ml-3 text-dark">Remark: {data?.remark}</span>
                </p>
            </div>
            <div className="">
                <h6 className="text-muted text-uppercase mb-3">Status</h6>
                <p>
                    <UserOutlined />
                    <span className="ml-3 text-dark">
                        <Switch disabled={props.loading} onClick={(v) => changeStatus(v)} checked={data?.status} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                    <Tag className ="text-capitalize ml-2"  color={data?.status === 0? 'red' : 'cyan'}>{data?.status===0? 'Inactive' : 'Active'}</Tag></span>
                </p>
                <div style={{marginTop:40}}>
                <Button type="primary" style={{width:84}} onClick={()=>props.onClickEdit()}> Edit</Button>
                <Button danger  style={{marginLeft:20}} onClick={()=>props.onClickDelete()}>Delete</Button>
                </div>
            </div>
            {/* <div className="mt-5">
                <h6 className="text-muted text-uppercase mb-3">CONTACT</h6>
                <p>
                    <MobileOutlined />
                    <span className="ml-3 text-dark">{data?.phone}</span>
                </p>
                <p>
                    <MailOutlined />
                    <span className="ml-3 text-dark">{data?.email? data?.email: '-'}</span>
                </p>
                <p>
                    <CompassOutlined />
                    <span className="ml-3 text-dark">{data?.street_address}</span>
                </p>
            </div> */}
        </Drawer>
    )

}

const mapStateToProps = state => ({
	loading:state.service.loading,
	user_status:state.user.user_status
})

const mapDispatchToProps = (dispatch) => {
    return{
        updateStatus: (status) => dispatch(updateStatus(status))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceView) 